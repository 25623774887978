import React from 'react';
import styled from "styled-components";
import media from 'styled-media-query';

export const Services = () => {


  return (
    <SContainer>
      <STitle>葬儀／供養</STitle>
      <SCommentContainer>
        <SCommentLeft>
          {/* お亡くなりになられた方の、葬儀など長きにわたり、故人の厚い供養をさせていただきます。寺院内でのご相談がございましたら、ご一報ください。 */}
          お亡くなりになった方の葬儀・年回法要など長きにわたり厚く故人を供養いたします。葬儀・供養に関するご相談がございましたらまずはご一報ください。
        </SCommentLeft>
        {/* <SContentTitle>寺院葬</SContentTitle> */}
        <SComment>
          葬儀のご相談
        </SComment>
        <SCommentLeft>
          現在では様々な形態で葬儀が行われております。当寺院でも、故人、ご遺族様のご意向をお伺いし、最善の形態での葬儀を行うよう努めております。故人への感謝の気持ちを一番に考え、永代に渡り手厚い供養を行うお手伝いをいたします。<br />
          <br />
        </SCommentLeft>
        <SComment>葬儀の流れ</SComment>
        <SInformationContainer>
          <br />
          まず、お寺にご連絡下さい。<br />
          ↓<br />
          <SCommentLeftInner>
            施主さまのご予定をお伺いし、故人に対して枕経のおつとめをいたします。枕経とは本来、死へ向かおうとしている方に対して仏の教えを説き、死への不安を取り除くためのおつとめです。現在はお亡くなりになってから行うので、故人の成仏を願う、仏の道への第一歩となるおつとめとなっています。<br />
          </SCommentLeftInner>
          ↓<br />
          お通夜<br />
          <SCommentLeftInner>
            現在ではお葬儀の前日の夜に行うのが一般的です。次の日には住み慣れた家、永く住んだ町、大切なご家族・友人とのお別れです。この世とのお別れとなります。ご家族・知人・友人の皆さまにとりましても故人と過ごすことのできる限られた時間です。心静かに皆さまで故人の遺徳を偲び故人と過ごします。<br />
          </SCommentLeftInner>
          ↓<br />
          出棺<br />
          その字のごとく、故人の旅立ちです。<br />
          ↓<br />
          葬儀<br />
          <SCommentLeftInner>
            葬儀は、故人が戒を授かる授戒会、導師が故人を仏の世界へ導く引導、皆さまとお別れをする告別式から成ります。授戒会では、故人の迷い、苦しみを断ち、戒名を授かります。次に導師の引導によって仏の世界へ導かれます。告別式は弔辞・弔電等、お別れのことばを述べ、焼香を行い故人とのお別れをします。戒名には故人を象徴するような文字を使用することを心がけております。苦しみのない世界で故人が心穏やかに過ごせるように戒名の授受をおすすめしています。
          </SCommentLeftInner>
        </SInformationContainer>
        <SContentTitle>法要</SContentTitle>
        <SComment>
          法要のご案内
        </SComment>
        <SCommentLeft>
          法事と法要についてできるだけわかりやすく説明したいと思います。一般に、私たちは「法事」と言っていますが、厳密に言いますと、住職にお経をあげてもらうことを「法要」といい、法要と後席の食事も含めた行事を「法事」と呼びます。当寺院では、故人を思い供養する忌日法要・年忌法要に心を込めておつとめしております。<br />
        </SCommentLeft>
        <SComment>忌日法要</SComment>
        <SInformationContainerCenter>
          <SCommentLeft>
            初七日（しょなのか）　<br />・・・命日も含めて７日目<br />
            二七日（ふたなのか）　<br />・・・命日も含めて１４日目<br />
            三七日（みなのか）　　<br />・・・命日も含めて２１日目<br />
            四七日（よなのか）　　<br />・・・命日も含めて２８日目<br />
            五七日（いつなのか）　<br />・・・命日も含めて３５日目<br />
            三十五日（さんじゅうごにち）とも<br />
            六七日（むなのか）　　<br />・・・命日も含めて４２日目<br />
            七七日（なななのか）　<br />・・・命日も含めて４９日目<br />
            四十九日（しじゅうくにち）とも<br />
            百カ日（ひゃっかにち）<br />・・・命日も含めて１００日目<br />
          </SCommentLeft>
        </SInformationContainerCenter>
        <SComment>年忌法要</SComment>
        <SInformationContainerCenter>
          <SCommentLeft>
            一周忌　　<br />・・・命日から満１年目<br />
            三回忌　　<br />・・・命日から満２年目<br />
            七回忌　　<br />・・・命日から満６年目<br />
            十三回忌　<br />・・・命日から満１２年目<br />
            十七回忌　<br />・・・命日から満１６年目<br />
            二十三回忌<br />・・・命日から満２２年目<br />
            二十七回忌<br />・・・命日から満２６年目<br />
            三十三回忌<br />・・・命日から満３２年目<br />
            三十七回忌<br />・・・命日から満３６年目<br />
            四十三回忌<br />・・・命日から満４２年目<br />
            四十七回忌<br />・・・命日から満４６年目<br />
            五十回忌　<br />・・・命日から満４９年目<br />
            百回忌　　<br />・・・命日から満９９年目<br />
          </SCommentLeft>
        </SInformationContainerCenter>
        <SContentTitle>先祖供養</SContentTitle>
        <SComment>
          寺院墓地
        </SComment>
        <SCommentLeft>
          大切なご先祖様を永く手厚く祀りたい方、様々なご事情でお墓参りや管理が困難な方も安心して供養していただけます。歴史の偉人が眠る地でご先祖様の御遺骨を大切にお預かりいたします。<br />
          <SInformationContainer>
            <SCommentLeft>
              墓地分譲中です。自然に囲まれた見晴らしのいい高台の地に、新しく墓地をご用意させていただきました。お気軽にお問い合わせください。<br />
            </SCommentLeft>
            <br />
            【お問い合わせ先】<br />
            電話　054-638-1023
          </SInformationContainer>
        </SCommentLeft>
        <SComment>
          永代供養墓／大楊の会<br />（長慶寺永代供養者の会）
        </SComment>
        <SCommentLeft>
          600年の歴史のあるお寺にて懇ろに供養いたします。<br /><br />
          ●お墓を建立しても、お墓の継承者がいない場合<br />
          ●ご遺族に負担をかけたくない場合<br /><br />
          永代供養は、本堂にて毎朝のお勤めの中で行います。埋葬は、合祀で行う場合と個人、夫婦で埋葬する方法があります。いずれも他人と遺骨が混ざることはありません。 <br />
        </SCommentLeft>
        {/* <SComment>
          ペット供養墓
        </SComment> */}
        <SContentTitle>ペット供養墓</SContentTitle>
        <SCommentLeft>
          共に暮らしたペットは大切な家族です。そのペットの遺骨を大切にお預かりし、手厚く供養いたします。
        </SCommentLeft>
      </SCommentContainer>
    </SContainer>
  );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
`
const STitle = styled.h2`
  text-align: center;
  letter-spacing: .1em;
`
const SCommentContainer = styled.div`
  margin: 0 auto;
  width: 70%;
  padding: 0 0 60px 0;
  ${media.lessThan("small")`
   width: 90%;
  `}
`
const SContentTitle = styled.h3`
  padding: 24px 0 0 0;
  text-align: center;
  letter-spacing: .1em;
`
const SComment = styled.p`
  padding: 24px 0 0 0;
  text-align: center;
  letter-spacing: .1em;
`
const SCommentLeft = styled(SComment)`
  text-align: left;
`
const SCommentLeftInner = styled(SCommentLeft)`
  padding: 0 0 0 0;
`
const SInformationContainer = styled.div`
  margin: 12px auto 24px;
  width: 80%;
  padding: 0px 24px 24px 24px;
  border: solid 1px;
  border-radius: 50px;
  text-align: center;
`
const SInformationContainerCenter = styled(SInformationContainer)`
  display: flex;
  justify-content: center;
`