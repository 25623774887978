import React from 'react';
import styled from "styled-components";
import media from 'styled-media-query';

export const About = () => {


  return (
    <SContainer>
      <STitle>基本情報／アクセス</STitle>
      <SCommentContainer>
        <SInformationContainer>
          <SCommentLeft>
            名　　称　山号　大楊山　長慶寺<br />
            宗　　派　臨済宗　妙心寺派<br />
            本　　尊　釈迦牟尼如来<br />
            開　　創　嘉慶年間<br />
            草創開山　不明<br />
            中興開山　太原崇孚和尚<br />
            住　　職　第十七世　渡邊一道<br />
            住　　所　〒426-0203<br />
            　　　　　静岡県藤枝市<br />
            　　　　　下之郷1225<br />
            電　　話　054-638-1023<br />
            F　A　X　054-638-1059<br />
          </SCommentLeft>
        </SInformationContainer>
        <SCommentLeft>
          ◆車でお越しの方<br />
          　新東名高速道路<br />
          　「藤枝岡部IC」より約10分<br />
          　浜松方面から藤枝バイパス<br />
          　「薮田西IC」より約5分<br />
          　静岡方面から藤枝バイパス<br />
          　「薮田東IC」より約5分<br />
          <br />
          ◆バスでお越しの方<br />
          　JR藤枝駅からしずてつジャストライン<br />
          　「葉梨中学校前」より徒歩にて約15分<br />
          　（約1km）<br />
          <br />
          ◆タクシーでお越しの方<br />
          　JR藤枝駅から約15分（約2,000円）
        </SCommentLeft>
      </SCommentContainer>
    </SContainer>
  );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
`
const STitle = styled.h2`
  text-align: center;
  letter-spacing: .1em;
`
const SCommentContainer = styled.div`
  margin: 0 auto;
  width: 30%;
  padding: 0 0 60px 0;
  ${media.lessThan("medium")`
   width: 90%;
  `}
`

const SComment = styled.p`
  padding: 24px 0 0 0;
  text-align: center;
  letter-spacing: .1em;
`
const SCommentLeft = styled(SComment)`
  text-align: left;
`
const SInformationContainer = styled.div`
  margin: 48px auto 0;
  width: 80%;
  padding: 0px 24px 24px 24px;
  border: solid 1px;
  border-radius: 50px;
`