import React from 'react';
import styled from 'styled-components';
import { HomeHeader } from '../organisms/home/HomeHeader';
import { HomeContents } from '../organisms/home/HomeContents';

const menus = [
  {
    type: "right",
    title:"由緒縁起",
    url:"/origins",
  },
  {
    type: "left",
    title:"年間行事",
    url:"/events",
  },
  {
    type: "right",
    title:"寺スクール",
    url:"/schools",
  },
  {
    type: "left",
    title:"御朱印",
    url:"/goshuin",
  },
  {
    type: "right",
    title:"今月の掲示板",
    url:"/board",
  },
  {
    type: "left",
    title:"お知らせ",
    url:"/notice",
  },
  {
    type: "right",
    title:"葬儀／供養",
    url:"/services",
  },
  {
    type: "left",
    title:"基本情報／アクセス",
    url:"/about",
  },
]

export const Home = () => {
  return (
    <>
     <HomeHeader></HomeHeader>
      <SMainContainer>
        <HomeContents menus={menus}></HomeContents>
      </SMainContainer>
    </>
  );
}

const SMainContainer = styled.div`
`
