import React from 'react';
import styled from "styled-components";
import media from 'styled-media-query';
import { LinkButton } from '../../atoms/button/TopButton';
import TopImage from "../../pages/img/choukeiji.jpg"
import bgTemplate from "../../pages/img/template.jpg"

export const HomeContents = (props) => {
  const { menus } = props;
  return (
    <>
      <SImgContainer>
        <SImg src={TopImage} alt="TopImage"></SImg>
      </SImgContainer>
      {/* {
        React.Children.toArray(menus.map((e) => (
          // 条件分岐で１つ目２つ目とかで分けるか
          <SContentsContainer>
            {
              e.type === "right" ?
                (
                  <>
                    <>
                      <SContentTitle>{e.title}</SContentTitle>
                    </>
                    <SContentContainer>
                      <SContentRight>
                        <LinkButton url={e.url}>詳細</LinkButton>
                      </SContentRight>
                    </SContentContainer>
                  </>
                )
                :
                (
                  <>
                    <>
                      <SContentTitle>{e.title}</SContentTitle>
                    </>
                    <SContentContainer>
                      <SContentLeft>
                        <LinkButton url={e.url}>詳細</LinkButton>
                      </SContentLeft>
                    </SContentContainer>
                  </>
                )
            }
          </SContentsContainer>
        )))
      } */}
      <SContainer>
        <SCommentContainer>
        <STitle>故人と私の心の安らかなることを願う</STitle>
          <SContentTitle>お寺の有る生活</SContentTitle>
          <SCommentLeft>
            大切な家族を亡くされたとき、葬儀に始まりいろいろなお勤め（法要）があります。法要・供養は、無理することなく自分が元気で健康なあいだは、故人が安らかなることを願ってお勤めいたしましょう。日常いつでもお寺に相談できる、お寺の有る生活は安心です。<br />
          </SCommentLeft>
          <SInformationContainer>
            <SCommentLeft>
              故人、先祖代々の位牌をお寺で預かってほしい。<br /><br />
              お仏壇を家に置くお勤めをしたい（開眼供養）。<br /><br />
              お墓を新しく建立したので、納骨のお経をお願いしたい。<br /><br />
              年忌法要やお盆の供養をしたい。<br /><br />
              永代供養をお願いしたい、その他供養方法を知りたい。<br /><br />
            </SCommentLeft>
          </SInformationContainer>
          <SContentTitle>たとえば葬儀</SContentTitle>
          <SCommentLeft>
            葬儀は、何時（いつ）やってくるか予測できません。しかし、いざ葬儀となると時間に追われ、あれよあれよという間に終わっているのが現実です。葬儀とは、故人とこの世で出会えたことに感謝し、ともに安らかなることを願い、故人がこれから仏さまの世界に無事に到達できるようお勤めすることです。現代では、エンディングノートを作成している方もいらっしゃいます。葬儀は皆さんにとって大切なことですから事前にお寺とご相談下さい。
            <SInformationContainer>
              <SCommentLeft>
                『葬儀』のことが頭に浮かんだら、まずお寺に気楽にご相談ください。<br />
                事前に内容を打ち合わせいたします。お電話でも大丈夫です。<br />
                もしもの時はお寺にご連絡いただき、施主様の意向に沿った段取りをします。<br /><br />
                故人が仏さまとのご縁をいただき、安らかなることを念じて枕経のお勤めをいたします。<br />
                お名前や誕生日、大切にしてきたことなどをお聞きし、ご遺族に沿った日程を決めていきます。<br /><br />
                通夜法要を行います。亡き人を偲び、戒名の願いや意味をご説明いたします。<br />
                翌日、打ち合わせした日程により出棺・葬儀・初七日のお勤めをいたします。
              </SCommentLeft>
              <SComment>
              【お問い合わせ先】<br />
              電話　054-638-1023
              </SComment>
            </SInformationContainer>
          </SCommentLeft>
        </SCommentContainer>
      </SContainer>

    </>
  );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
`
const SCommentContainer = styled.div`
  margin: 0 auto;
  width: 70%;
  padding: 0 0 60px 0;
  ${media.lessThan("small")`
   width: 90%;
  `}
`
const SContentTitle = styled.h3`
  padding: 24px 0 0 0;
  text-align: center;
  letter-spacing: .1em;
`
const SComment = styled.p`
  padding: 24px 0 0 0;
  text-align: center;
  letter-spacing: .1em;
`
const SCommentLeft = styled(SComment)`
  text-align: left;
`
const STitle = styled.h2`
  text-align: center;
  letter-spacing: .1em;
`
const SImgContainer = styled.div`
  max-width: 1170px;
  margin: 24px auto;
  text-align: center;
`
const SImg = styled.img`
  width: 80%;
  height: 80%;
`
const SInformationContainer = styled.div`
  margin: 48px auto 36px;
  width: 80%;
  padding: 0px 24px 24px 24px;
  border: solid 1px;
  border-radius: 50px;
`
const SContentContainer = styled.div`
  border: solid #604C32;
  width: 100%;
  height: 60%;
  margin: 36px auto 0px auto;
  background-size: cover;
  background-position: center;
  background-image: url('${bgTemplate}');
`
const SContentAbout = styled(SContentContainer)`

`
const SContentWorks = styled(SContentContainer)`

`
const SContentContact = styled(SContentContainer)`

`
const SContent = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.lessThan("small")`
   width: 100%;
  `}
`
const SContentLeft = styled(SContent)`

`
const SContentRight = styled(SContent)`
margin: 0 0 0 auto;
`