import React from 'react';
import styled from "styled-components";

export const Goshuin = () => {


  return (
    <SContainer>
      <STitle>御朱印</STitle>
      <SCommentContainer>
        {/* <SInformationContainer>
          <SCommentLeft>
            ＊詳細
          </SCommentLeft>
        </SInformationContainer> */}
        <SCommentLeft>
          ご参拝下さる方の御朱印は受け付けております。ただし、住職不在の時、法務中等お受けできないときもございますのでご承知の上ご来山下さい。書き置きも用意してありますが、数に限りがございます。花まつりには限定御朱印（書き置きのみ）もご用意しております。
        </SCommentLeft>
      </SCommentContainer>
    </SContainer>
  );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
`
const STitle = styled.h2`
  text-align: center;
  letter-spacing: .1em;
`
const SCommentContainer = styled.div`
  margin: 0 auto;
  width: 70%;
  padding: 0 0 60px 0;
`

const SComment = styled.p`
  padding: 24px 0 0 0;
  text-align: center;
  letter-spacing: .1em;
`
const SCommentLeft = styled(SComment)`
  text-align: left;
`
const SInformationContainer = styled.div`
  margin: 48px auto 0;
  width: 80%;
  padding: 0px 24px 24px 24px;
  border: solid 1px;
  border-radius: 50px;
`