import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const LinkButton = (props) => {
  return (
    <>
      <SLink to={props.url}>{props.children}</SLink>
    </>
  );
}
const SLink = styled(Link)`
  border: solid #604C32;
  color: #604C32;
  background-color: #e8dfd3;
  text-decoration: none;
  display:block;
  margin: 16px auto 0 auto;
  width: fit-content;
  padding: 14px 28px;
  &:hover {
    cursor: pointer;
  }
  &:active {
    background: #fff;
    opacity: .6;
    color: #000;
  }
`