import React from 'react';
import styled from 'styled-components';
import thumnailShakyou from './img/shakyou.jpg'
import thumnailGoeika from './img/goeika.jpg'
import thumnailTolePainting from './img/tolepainting.jpg'
import thumnailStretchYoga from './img/stretchyoga.jpg'

const schools = [
  {
    url: "#",
    title: "写経の会",
    comment: "毎月第1・第3土曜日　午前8時30分から",
    background: thumnailShakyou,
  },
  {
    url: "#",
    title: "御詠歌",
    comment: "毎月第1・第3月曜日　午前7時30分から／毎月第1・第4水曜日　午前9時30分から",
    background: thumnailGoeika,
  },
  {
    url: "#",
    title: "トールペイント",
    comment: "毎月第3金曜日　午前10時から（外部講師）",
    background: thumnailTolePainting,
  },
  {
    url: "#",
    title: "ストレッチヨガ",
    comment: "毎月第3日曜日　午後3時から（外部講師）",
    background: thumnailStretchYoga,
  },
]

export const Schools = () => {
  return (
    <>
      <SContainer>
        <STitle>寺スクール</STitle>
        <SSchools>
          {
            React.Children.toArray(schools.map((g, i) => {
              return (
                <>
                  <SSchool>
                    <SSchoolTitleContainer>
                      <SWorkTitle>{g.title}</SWorkTitle>
                    </SSchoolTitleContainer>
                    <SCommentContainer>
                      <SComment>{g.comment}</SComment>
                    </SCommentContainer>
                    <SImg src={g.background} />

                  </SSchool >
                </>
              );
            }))
          }

        </SSchools>
      </SContainer >
    </>
  );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
`
const STitle = styled.h2`
  text-align: center;
  letter-spacing: .1em;
`
const SSchools = styled.div`
  margin: 20px 0 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  justify-content: center;
`
const SSchool = styled.div`
  margin: 0 20px 70px;
  max-width: 400px;
  position:		relative;
`
const SSchoolTitleContainer = styled.div`
  height: 24px;
`
const SWorkTitle = styled.h3`
  letter-spacing: .1em;
  margin: 12px 0 0 12px;
  white-space: pre-wrap;
`
const SCommentContainer = styled.div`
  height: 60px;
`
const SComment = styled.div`
  font-size: 12px;
  letter-spacing: .1em;
  margin: 12px 0 0 12px;
`
const SImg = styled.img`
  width: 100%;
  transition: .2s;
  &:hover {
    cursor: pointer;
  }
`