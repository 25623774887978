import React from 'react';
import styled from "styled-components";
import media from "styled-media-query";

export const EventCard = (props) => {
  const { step } = props;

  return (
    <>
      {
        React.Children.toArray(step.map((e) => {
          return(
            <>
              <SStartDate>{e.startDate}</SStartDate>
              <SContainer>
                <STitle>{e.title}</STitle>
                <SStartDateInStep>{e.startDate}</SStartDateInStep>
                <SContent>{e.content}</SContent>
              </SContainer>
            </>
          );
        }))
      }
    </>
  );
}

const SContainer = styled.div`
  color: #000;
  padding: 18px 0 18px 12px;
  margin: 24px 50px 0 32px;
  position: relative;

  /* 下線 */
  ::after {
    content: "";
    display: block;
    width: 101%;
    height: 1px;
    background: #604C32;
    position: absolute;
    bottom: 0;
  }

  
`
const STitle = styled.h3`
`

const SStartDate = styled.p`
  display: none;
  position: absolute;
  line-height: 24px;


`
const SStartDateInStep = styled.p`
  margin: 48px 0 0 0;
  line-height: 24px;


`
const SContent = styled.p`
  line-height: 24px;
`