import React from 'react';
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { DefaultLayout } from "../templates/DefaultLayout"
import { ScrollTop } from "./ScrollTop"
import { Home } from "../pages/Home";
import { Origins } from "../pages/Origins";
import { Events } from "../pages/Events";
import { Schools } from "../pages/Schools";
import { Goshuin } from "../pages/Goshuin";
import { Notice } from "../pages/Notice";
import { Services } from "../pages/Services";
import { About } from "../pages/About";



export const Router = () => {
  return(
    <BrowserRouter>
    <ScrollTop />
      <Switch>
        <Route exact path="/">
          <DefaultLayout>
            <Home></Home>
          </DefaultLayout>
        </Route>
        <Route exact path="/origins">
          <DefaultLayout>
            <Origins></Origins>
          </DefaultLayout>
        </Route>
        <Route exact path="/events">
          <DefaultLayout>
            <Events></Events>
          </DefaultLayout>
        </Route>
        <Route exact path="/schools">
          <DefaultLayout>
            <Schools></Schools>
          </DefaultLayout>
        </Route>
        <Route exact path="/goshuin">
          <DefaultLayout>
            <Goshuin></Goshuin>
          </DefaultLayout>
        </Route>
        <Route exact path="/notice">
          <DefaultLayout>
            <Notice></Notice>
          </DefaultLayout>
        </Route>
        <Route exact path="/services">
          <DefaultLayout>
            <Services></Services>
          </DefaultLayout>
        </Route>
        <Route exact path="/about">
          <DefaultLayout>
            <About></About>
          </DefaultLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
