import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from "styled-media-query";


export const Fotter = () => {
  return (
    <>
      <SFotter>
        <SLinks>
          <SNav>
            <SUl>
              <SLink to="/" style={{color: "#000"}}>Top</SLink>
              <SLink to="/origins" style={{color: "#000"}}>由緒縁起</SLink>
              <SLink to="/events" style={{color: "#000"}}>年中行事</SLink>
              <SLink to="/goshuin" style={{color: "#000"}}>御朱印</SLink>
              <SLink to="/services" style={{color: "#000"}}>葬儀／供養</SLink>
              <SLink to="/schools" style={{color: "#000"}}>寺スクール</SLink>
              <SLink to="/notice" style={{color: "#000"}}>今月の掲示板</SLink>
              <SLink to="/about" style={{color: "#000"}}>基本情報／アクセス</SLink>
            </SUl>
          </SNav>
        </SLinks>
        <SP>&copy; 2023 長慶寺</SP>
      </SFotter>
    </>
  );
}

const SFotter = styled.footer`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 9999;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 240px;
  `}
`
const SLinks = styled.div`
  color: #00828C;
  margin: 36px 0 0 0;
  display: flex;
  align-items: center;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    
  `}
`
const SNav = styled.nav`
  margin: 0 20px 0 0;
  position:relative;
  ::after {
   content: "";
   display: inline-block;
   width: 1px;
   height: 20px;
   position: absolute;
   top: 0px;
   right: -10px;
  }

  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    ::after {
      content: none;
    }
  `}
`
const SUl = styled.ul`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    display: flex;
    flex-flow: column;
  `}
`
const SLink = styled(Link)`
  letter-spacing: .1em;
  padding: 20px 15px;
  text-decoration: none;
  &:hover{
    cursor: pointer;
  }
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    padding: 0px 10px;
  `}
`
const SP = styled.p`
  font-size: 6px;
  letter-spacing: .1em;
  margin: 10px 0 30px 0;
  `