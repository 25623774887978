import React from 'react';
import styled from "styled-components";
import media from 'styled-media-query';


export const HomeHeader = () => {

  return (
    <>
      <SContainer>
        <SContent>
          臨済宗　妙心寺派<br />
          今川泰範公菩提寺
        </SContent>
      </SContainer>
    </>
  );
}

const SContainer = styled.div`
  margin: 60px auto;
  text-align: center;
  min-height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const SContent = styled.p`
  margin: 30px auto 0 auto;
  font-size: 24px;
  letter-spacing: .1em;
  line-height: 36px;
  color: #000;
  width: 90%;
  max-width: 500px;
`