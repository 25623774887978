import React from 'react';
import styled from "styled-components";
import media from 'styled-media-query';

export const Origins = () => {


    return (
        <SContainer>
            <STitle>由緒縁起</STitle>
            <SCommentContainer>
                <SCommentLeft>
                    　長慶寺は、『駿河記』等によると、嘉慶年間（1387〜1388年）鬼岩寺末の真言宗として開創されたと記されている。その後、真言宗として法灯を150年余にわたって守り続けたが、今川<ruby>義元<rt>よしもと</rt></ruby>の軍師であった太原崇孚（<ruby>雪斎<rt>せっさい</rt></ruby>）の姉と言われている<ruby>太年尼<rt>たいねんに</rt></ruby>が、廃れていた長慶寺の再興に手がけ、更に太原崇孚の功労で再興を果たした。宗派も真言宗から臨済宗妙心寺派となり、太原は中興開山となった。
                </SCommentLeft>
                <SContentTitle>今川氏</SContentTitle>
                <SCommentLeft>
                    　足利氏の一族で、駿河を本拠として遠江・三河にも進出した守護大名・戦国大名で足利<ruby>義氏<rt>よしうじ</rt></ruby>の子、<ruby>吉良長氏<rt>きらながうじ</rt></ruby>の次子、<ruby>国氏<rt>くにうじ</rt></ruby>が三河国幡豆郡今川荘（現在の愛知県西尾市今川町一帯）を領して、今川氏を称したことに始まる。今川氏発展の基礎を作った<ruby>範国<rt>のりくに</rt></ruby>は、足利尊氏に従って行動し、鎌倉幕府滅亡後、遠江・駿府両国守護や室町幕府の引付頭人に任じられた。以後、駿府守護職は今川氏によって世襲され、<ruby>範氏<rt>のりうじ</rt></ruby>、<ruby>氏家<rt>うじいえ</rt></ruby>、<ruby>泰範<rt>やすのり</rt></ruby>、<ruby>範政<rt>のりまさ</rt></ruby>と伝えられた。また、範国の次子、<ruby>貞世<rt>さだよ</rt></ruby>（<ruby>了俊<rt>りょうしゅん</rt></ruby>）は長く九州探題として活躍した。範政は応永23年（1416年）の上杉<ruby>禅秀<rt>ぜんしゅう</rt></ruby>の乱で活躍したが、その後継問題とからんで国人層の反乱が起こった。反乱の鎮定後、長子<ruby>範忠<rt>のりただ</rt></ruby>が家督を継ぎ、享徳の乱では征東大将軍として足利<ruby>成氏<rt>しげうじ</rt></ruby>を破った。<br />
                    <br />
                    　その子、<ruby>義忠<rt>よしただ</rt></ruby>は、応仁の乱に際して東軍として上洛し、帰国後、遠江経略に力を注いだが、文明8年（1476年）討死した。その結果、再び家督争いが起こったが、<ruby>伊勢<rt>いせ</rt>長氏<rt>ながうじ</rt></ruby>（北条<ruby>早雲<rt>そううん</rt></ruby>）の調停で義忠の子、<ruby>龍王丸<rt>りゅうおうまる</rt></ruby>が家督を継ぎ、<ruby>氏親<rt>うじちか</rt></ruby>と名乗った。氏親は、印判状の発給、駿府市街の整備、遠江・三河への進出、領内検地などを行い、この時代に今川氏は戦国大名化した。永正5年（1508年）には遠江守護職を獲得し、大永6年（1526年）には戦国法として著名な「今川仮名目録」を制定した。<br />
                    <br />
                    　氏親の死後、<ruby>氏輝<rt>うじてる</rt></ruby>が継ぎ、その死後、天文5年（1536年）に義元が継いだ。義元は領国の安定化を図る一方、周辺の<ruby>後北条<rt>ごほうじょう</rt></ruby>氏、松平氏、織田氏と戦い、駿府・遠江・三河3ヵ国を支配した。天文22年（1553年）には「仮名目録追加」を制定し、翌年には武田氏・後北条氏と同盟を結んだが、上洛を期して西上中であった永禄3年（1560年）5月、尾張桶狭間で織田信長の奇襲にあって敗死した。子の<ruby>氏真<rt>うじざね</rt></ruby>は、武田氏・松平氏の攻撃にあって後北条氏のもとへ逃れた。のちに徳川家康に保護され、子孫は高家として命脈を保った。
                </SCommentLeft>
                <SContentTitle>今川<ruby>泰範<rt>やすのり</rt></ruby></SContentTitle>
                <SCommentLeft>
                    　開基の今川泰範は範氏の次男であり、若い頃は鎌倉の建長寺に入り修行していたが、兄の氏家が若くして没したため還俗して、今川三代を継いだ。<br />
                    <br />
                    　足利義満に仕え、明徳の乱と応永の乱で功名をあげ、応永7年（1400年）駿府と遠江2ヵ国の守護職に任ぜられた。それ以前、泰範の父範氏は文和2年（1353年）頃駿府の地を治める根拠地として島田の大津庄には大津城を、葉梨庄には花倉城を築いた。この築城に並行して家臣の屋敷を定め、一族の菩提を弔ったり、男子教育の場として氏寺を建立した。花倉には遍照光寺（表記ゆれ）を、下之郷には長慶寺が開創されたのである。開基泰範は嘉慶2年（1409年）76歳の生涯を閉じると、遺骸は長慶寺に葬られたと言われ、その五輪塔が現在境内に残っている。
                </SCommentLeft>
                <SContentTitle>太原崇孚</SContentTitle>
                <SCommentLeft>
                    　雪斎は9歳で善得寺（現在の富士市にあったが今は遺跡のみ）に入り、永正6年（1509年）14歳のとき京都の建仁寺で得度して仏門に入った。名を<ruby>承菊<rt>しょうぎく</rt></ruby>と称した。今川7代目氏親は、我が子菊丸（のちの今川義元）の教育を雪斎のもとに委ねた。大永5年（1525年）義元は<ruby>栴岳<rt>せんがく</rt></ruby><ruby>承芳<rt>しょうほう</rt></ruby>と改め仏門に入った。雪斎は承芳を連れて再び建仁寺に入山して学んだが、その後、花園の妙心寺に入り<ruby>大休<rt>だいきゅう</rt></ruby><ruby>宗休<rt>そうきゅう</rt></ruby>につく。この宗休は、後に請われて臨済寺の初代となった僧である。京都から富士の善得寺に帰山したのは天文4年（1525年）、雪斎が40歳、承芳が17歳であった。翌年、今川8代の承芳の兄氏輝が24歳で死去すると、承芳と異母兄の<ruby>良真<rt>りょうしん</rt></ruby>（<ruby>恵探<rt>えたん</rt></ruby>）との間に、家督相続の争いが起きた。良真を推すのは今川<ruby>範将<rt>のりまさ</rt></ruby>および異母方の福島氏一党だけであった。雪斎は僧の身ではあったが還俗した義元を助け、岡部、由比、瀬名氏とともに一気に恵探を攻め、花倉城に追い詰めた。恵探は山伝いに瀬戸谷の普門寺に逃れたが、そこで自刃してしまった。<br />
                    <br />
                    　雪斎はこのあたりから、義元の影で参謀的な役割を果たし始めた。今川義元と、武田信玄、北条<ruby>氏康<rt>うじやす</rt></ruby>との「善得寺の盟約」の実現の際、雪斎が調停役になったのは疑いない。雪斎は義元が駿府、遠江、三河にわたって権力を確立していく際ともに行動した。家康をして「義元は、雪斎を過度に信頼して重臣を軽んじた」といわしめたほど、義元は雪斎を頼りにしていた。義元は善得院（後の臨済寺）に兄氏輝を葬り、大龍山臨済寺を再建して、雪斎を中興の祖にしようとした。しかし雪斎はこれを受けず、師である妙心寺の大休を招いた。<br />
                    <br />
                    　なお、今川氏の人質にあった家康が雪斎に薫陶を受けたのはこの臨済寺であった。雪斎は太原崇孚と称し、妙心寺の35世を務めたこともある。
                </SCommentLeft>
                <SContentTitle>伝説</SContentTitle>
                <SInformationContainer>
                    <SCommentLeft>
                        《<ruby>陀蛻<rt>だぜい</rt></ruby>（へびのぬけがら）》<br />
                        <br />
                        　文化6年（1809年）『駿河記』の著者、桑原藤泰が長慶寺を訪れ、今川泰範の墓をお参りした。すると五輪塔の上に蛇のぬけがらがかかっていたので、このことについて聞いたところ不思議な話を聞いた。住職の話によると前の住職の時、ある僧がこの寺を訪れた。そこで話がはずんだ。泰範公の塔の中に今川義元の分骨を合葬したと言い伝えられている。そのことを記録した文書は残っていないが、寺の位牌が両名の合同位牌であるから何かいわれがあるだろう。と前の住職は墓の中にある墓誌をさがし確認したいと客僧に相談した。<br />
                        <br />
                        　そこで二人が五輪塔の笠石をとりのけたところ、塔の中央に丸い石柱が台座を貫いている。この石柱を2、3寸引きあげたところ、小さな蛇が石柱にまといついていた。うろこは五色の斑点があり、金色の光を放っている。小蛇はみるみるうちに太くなり首をあげて奮慾の勢いをなしてから、柱の下にもぐってしまった。二人とも大変驚き、怖くなったが、もう一度石柱を引き抜こうとして持ちあげた。すると小蛇はさきほどよりもっと体を膨らませ、首をもちあげ、眼は赤く染まり怒りの形相を張らせている。思わず背筋が寒くなった二人は意気消沈し、身の毛もよだち、石柱を抜き取ることをやめ、小蛇をそのままにして笠石を元のように戻しておいた。その恐怖から客僧もしばらくして自分の寺に帰っていった。<br />
                        <br />
                        　その夜のことである。住職は悪感が起り、陰部がにわかに痛み出しはれあがり、高熱を発した。苦痛に耐えられず医師を招いて服薬したが治らない。そのうちに下腹部だけでなく身体中がうみ、まもなく示寂したという。<br />
                        <br />
                        　その後桑原藤泰が益津郡右脇村の宝積寺を訪れ、旧知の素白和尚が住持だったので1泊した。話がはずみ、長慶寺の奇談を話したところ、素自和尚は手を打って答えた。その客僧とは実は私のことなのである。実は私もあの時寺に帰山すると寒気を覚え高熱が出た。便所に行くと陰部がはれあがり、痺痛で1晩中眠れない。翌朝医師に行き治療してもらったが、なかなか治らない。しばらく病床に伏していると長慶寺和尚も同じような病状であると聞いた。私はこれはきっとあの泰範の塔の神霊のたたりに違いないと思い、苦痛があるときは誦経し、霊廟をあけたことを陳謝し、今川家の菩提供養を続けた。するとしばらくするとようやく病も平癒したという。
                    </SCommentLeft>
                </SInformationContainer>
            </SCommentContainer>
        </SContainer>
    );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
`
const SCommentContainer = styled.div`
  margin: 0 auto;
  width: 70%;
  padding: 0 0 60px 0;
  ${media.lessThan("small")`
   width: 90%;
  `}
`
const SInformationContainer = styled.div`
  margin: 48px auto 0;
  width: 80%;
  padding: 0px 24px 24px 24px;
  border: solid 1px;
  border-radius: 50px;
`
const SContentTitle = styled.h3`
  padding: 24px 0 0 0;
  text-align: center;
  letter-spacing: .1em;
`
const SComment = styled.p`
  padding: 24px 0 0 0;
  text-align: center;
  letter-spacing: .1em;
`
const SCommentLeft = styled(SComment)`
  text-align: left;
`
const STitle = styled.h2`
  text-align: center;
  letter-spacing: .1em;
`