import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import axios from 'axios';

export const Notice = () => {

  const [caption_1, setCaption_1] = useState([])
  const [caption_2, setCaption_2] = useState([])
  const [caption_3, setCaption_3] = useState([])
  const [caption_4, setCaption_4] = useState([])
  const [caption_5, setCaption_5] = useState([])

  const [url_1, setUrl_1] = useState([])
  const [url_2, setUrl_2] = useState([])
  const [url_3, setUrl_3] = useState([])
  const [url_4, setUrl_4] = useState([])
  const [url_5, setUrl_5] = useState([])

  const [clickUpdate, setClickUpdate] = useState(false)

  const onClickUpdate = () => {
    setClickUpdate(!clickUpdate)
  }

  useEffect(() => {
    const user_name = "chokeiji_imagawa" //ビジネスorクリエイターアカウントの必要あり
    const access_token = "EAAEZAstQFoWcBOyGZAFYqB0FBjIZAvoBw2HVxG6mvShnLMWTBboh4eLvkl7fQ29VmbufPRE8zlAXYHm7gUvznsukrDWLGNOZAwxRf5Nl64re1WvdSD6itBa3h6VjcLRXp0nyndoE05U6z5j9lWZBALILI4nZCA3XydS6tja8AGONcZBffpqY0nEr63NMFsfrV4V6ZAPaZCZBQZD"
    const user_id = "17841462812089469"
    const get_count = 5 //取得したい投稿数
    axios
      .get(
        `https://graph.facebook.com/v18.0/${user_id}?fields=business_discovery.username(${user_name}){id,followers_count,media_count,ig_id,media.limit(${get_count}){caption,media_url,like_count}}&access_token=${access_token}`
      )
      .then((res) => {

        const res_data = res.data
        console.log(res_data)

        const res_bd = res_data.business_discovery
        console.log(res_bd)

        const res_media = res_bd.media
        console.log(res_media)

        const res_caption_1 = res_media.data[0].caption
        const res_caption_2 = res_media.data[1].caption
        const res_caption_3 = res_media.data[2].caption
        const res_caption_4 = res_media.data[3].caption
        const res_caption_5 = res_media.data[4].caption

        setCaption_1(res_caption_1)
        setCaption_2(res_caption_2)
        setCaption_3(res_caption_3)
        setCaption_4(res_caption_4)
        setCaption_5(res_caption_5)

        const res_mu_1 = res_media.data[0].media_url
        const res_mu_2 = res_media.data[1].media_url
        const res_mu_3 = res_media.data[2].media_url
        const res_mu_4 = res_media.data[3].media_url
        const res_mu_5 = res_media.data[4].media_url

        setUrl_1(res_mu_1)
        setUrl_2(res_mu_2)
        setUrl_3(res_mu_3)
        setUrl_4(res_mu_4)
        setUrl_5(res_mu_5)

      })
  }, [clickUpdate])

  return (
    <SContainer>
      <STitle>お知らせ</STitle>

      <SCommentContainer>
        <SImgContainer>

          <SInformationContainer>
            <SCommentLeft>{caption_1}</SCommentLeft>
            <SImg src={url_1} alt='instagramImage'></SImg>
          </SInformationContainer>

          <SInformationContainer>
            <SCommentLeft>{caption_2}</SCommentLeft>
            <SImg src={url_2} alt='instagramImage'></SImg>
          </SInformationContainer>

          <SInformationContainer>
            <SCommentLeft>{caption_3}</SCommentLeft>
            <SImg src={url_3} alt='instagramImage'></SImg>
          </SInformationContainer>

          <SInformationContainer>
            <SCommentLeft>{caption_4}</SCommentLeft>
            <SImg src={url_4} alt='instagramImage'></SImg>
          </SInformationContainer>

          <SInformationContainer>
            <SCommentLeft>{caption_5}</SCommentLeft>
            <SImg src={url_5} alt='instagramImage'></SImg>
          </SInformationContainer>

        </SImgContainer>
        {/* <button onClick={onClickUpdate}>更新</button> */}
      </SCommentContainer>
    </SContainer>
  );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
`
const STitle = styled.h2`
  text-align: center;
  letter-spacing: .1em;
`
const SCommentContainer = styled.div`
  margin: 0 auto;
  width: 70%;
  padding: 0 0 60px 0;
`
const SImgContainer = styled.div`
  max-width: 1170px;
  margin: 24px auto;
  text-align: center;
`
const SInformationContainer = styled.div`
  margin: 48px auto 0;
  width: 80%;
  padding: 12px 24px 6px 24px;
  border: solid 1px;
  border-radius: 50px;
`
const SComment = styled.p`
  text-align: center;
  letter-spacing: .1em;
`
const SCommentLeft = styled(SComment)`
  text-align: left;
  margin: 12px 0 12px 12px;
`
const SImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 25px;
  margin: 0 auto 12px;
`