import React from 'react';
import styled from "styled-components";
import media from "styled-media-query";
import { EventCard } from "../organisms/event/EventCard";


const events = [
  {
    title: '年頭のあいさつ',
    startDate: '1月1日　午前8時30分より',
    content: `１年の始まりの日、その年無事に過ごせますよう、まずはご先祖様や故人へ最初のあいさつにお越しください。`,
  },
  {
    title: '明徳稲荷供養',
    startDate: '2月初午の日',
    content: `本堂西側、明徳稲荷大明神にて供養祭を行います。明徳稲荷大明神をお祀りしてあるのは、長慶寺開基今川泰範公が明徳の乱で功績をあげたことに由来するといわれています。どういう関連のご利益かはわかりませんが、お参りした方から「無くしたものが見つかった」というお話をお聞きしています。`,
  },
  {
    title: '大般若会',
    startDate: '3月14日',
    content: `長慶寺の大般若経は六百巻あります。これは約１６０年前、江戸を中心にしてこの地域にも麻疹が流行した時、流行終息を願う県中部地域の人たちによって寄進されたものです。以来、毎年の大般若祈祷会で家内安全・無病息災を願い、住職をはじめとして近隣の和尚さまがた１０数名により転読されております。`,
  },
  {
    title: '春彼岸供養',
    startDate: '春分の日　午前8時30分より',
    content: `本堂にて春彼岸供養をしております。春彼岸供養ではお塔婆供養も受け付けています。お塔婆供養のお申し込みは申込書を配付しております。お電話でもご郵送でも受け付けています。`,
  },
  {
    title: '花まつり',
    startDate: '4月8日近辺の土曜日・日曜日　午前8時頃より',
    content: `本堂前にてお釈迦様のお誕生日をお祝いします。本堂前に花御堂を安置し、来山された皆さまに自由にお参りいただいております。無相教会会員が釈迦如来御誕生御詠歌を奉詠します。花まつり限定の御朱印(書置き)もご用意しています。花御堂も毎年趣向を凝らし、季節の花で飾ります。春の花で飾られる花御堂も楽しみにお参りください。`,
  },
  {
    title: '迎え盆供養・送り盆供養',
    startDate: '7月1日から8月24日まで',
    content: `盆供養を行っております。①ご自宅に伺ってご自宅のお盆棚でのお盆のお経　②お位牌や故人の写真などをお持ちいただいて寺でのお盆のお経　③寺での合同盆供養　①～③のように「それぞれの供養のかたち」に合わせてお盆の供養をしています。`,
  },
  {
    title: '地蔵盆供養',
    startDate: '8月24日',
    content: `下之郷各地区の地蔵盆供養です。各地区でお地蔵さまの供養が行われます。ふだんからお地蔵さまに手を合わせる方は多く、皆さまの信仰をあつめているお地蔵さまですが、お地蔵さまはとくに子どもたちをお守りくださる仏さまです。地蔵盆のときは子どもさんも多くお参りくださいます。`,
  },
  {
    title: '開山命日',
    startDate: '10月10日',
    content: `開山太原雪斎和尚様の命日です。`,
  },
  {
    title: '除夜の鐘',
    startDate: '12月31日　受付時間　午後11時30分から12時まで',
    content: `一年間無事に終えたことの感謝を込めて除夜の鐘を打ちます。受付時間内にお越しくださればどなたでもご参加になれます。`,
  },
]

export const Events = () => {
  return (
    <>
      <STitleContainer>
        <STitle>年中行事</STitle>
      </STitleContainer >
      <SContainer>
        <EventCard step={events}></EventCard>
      </SContainer>
    </>
  );
}

const STitleContainer = styled.div`
  padding: 24px 0 0 0;
  text-align: center;
`
const STitle = styled.h2`
  color: #000;

`
const SImgContainer = styled.div`
  max-width: 1170px;
  margin: 24px auto 0;
  text-align: center;
`
const SImg = styled.img`
  width: 80%;
  height: 80%;
`
const SContainer = styled.div`
  margin: 0px auto 120px;
  width: 80%;
  max-width: 1170px;
  position: relative;
  ::before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 105%;
    background: #604C32;
    position: absolute;
    left: 30px;
  }

`